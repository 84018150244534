// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { apiUrl, harmonyId, harmonyApiKey, membersUrl, marketingUrl, rootDomain } from './settings';
import { RenewableSubsidyApplicationProvider } from '@brokerportal/common/enums';

export const environment = {
    type: '',
    envName: '',
    fullEnvName: '',
    bypassAuthentication: false,
    maintenanceMode: false,
    maintenanceEstimate: '30 minutes',
    useBeamer: false,
    useDatadogRum: false,
    datadogApplicationId: '',
    datadogClientToken: '',
    baseAppUrl: 'http://localhost:4202',
    membersUrl: 'http://localhost:20100',
    maxUploadFileSize: 31457280, //30MB
    api: {
        baseUrl: apiUrl,
        identityServiceUrl: 'http://localhost:20028'
    },
    authentication: {
        host: apiUrl,
        token: 'portal/authentication/oauth2/token',
        key: 'f77edcab854a485399395bad55b0294c',
        withCredentials: true,
        tokens: {
            access: 'access_token',
            refresh: 'refresh_token'
        }
    },
    fusionAuth: {
        clientId: 'a39e6925-bb05-419b-a96b-b3fb78a9a19d',
        baseUrl: 'http://localhost:9011',
        tenantId: 'cbe5a0a3-a099-7e59-bab4-041859b7b54f',
        redirectUri: 'http://localhost:20100/login/Auth'
    },
    harmony: {
        userName: harmonyId,
        key: harmonyApiKey
    },
    useDirectQuoteService: true,
    quoteServiceHost: 'http://localhost:50012',
    providerBatteryPackagesRoute: 'packages',
    schemeCoverageRoute: 'schemeCoverage',
    approvedBuildersRoute: 'builders',
    constants: {
        branding: {
            title: 'Plenti',
            membersUrl: membersUrl,
            marketingUrl: marketingUrl,
            staticAssetsPath: '//static.plenti.com.au',
            images: {
                logoUrl: '//test.cdn.ratesetter.com.au/images/logo.svg'
            },
            contact: {
                contactEmail: `contact@${rootDomain}`,
                contactCarEmail: `carloans@${rootDomain}`,
                contactEHPEmail: `empoweringhomes@${rootDomain}`,
                contactHBSEmail: `homebatteryscheme@${rootDomain}`,
                contactPurpleBricksEmail: `purplebricks@${rootDomain}`,
                contactEnergyMasterEmail: `energymasters@${rootDomain}`
            },
            urls: {
                creditAdvicePL: 'https://web.ratesetter.com.au/broker/credit-advice/personal-loans',
                creditAdviceCar: 'https://web.ratesetter.com.au/broker/credit-advice/car-loans'
            }
        },
        storage: {
            portalConfigurationKey: 'RSBrokerPortalConfiguration_DEV',
            brokerSettingConfigurationKey: 'RSBrokerSettingConfiguration_DEV',
            workflowManifestConfigurationKey: 'RSWorkflowManifestConfiguration_DEV'
        },
        cookies: {
            oldLoginCookieName: 'rs_broker_access_token_truncated',
            brokerAccessTokenCookieName: 'rs_broker_access_token',
            brokerAccessTokenIndicatorCookieName: 'rs_broker_access_token_indicator',
            brokerAccessTokenTruncatedCookieName: 'rs_broker_access_token_truncated',
            brokerRefreshTokenCookieName: 'rs_broker_refresh_token'
        },
        settings: {
            tokenRefreshIntervalSeconds: 300
        },
        urls: {
            continueApplication: `${membersUrl}/applications/referral/continue/{applicationId}/{isBroker}`,
            continueApplicationStep: `${membersUrl}/applications/referral/continue/{applicationId}/{isBroker}/{stepName}`,
            referApplication: `${membersUrl}/refer/{referralCode}/{isBroker}?data={data}`,
            referExistingApplication: `${membersUrl}/applications/referral/refer/{applicationId}/{isBroker}`
        },
        assets: {
            defaultAvatarImageUrl: '/assets/img/avatars/noavatar.png'
        },
        redbookDeploymentStage: 'local' as 'local' | 'test' | 'prod'
    },
    microUi: {
        baseUrl: 'http://localhost:42013',
        useAppJsonIndirection: false,
        mockApi: false
    },
    featureScope: 'Development',
    features: {
        DashboardDealsCompletedCurrentMonthStatistics: true,
        DashboardDealsCommissionEarnedCurrentMonthStatistics: true,
        DashboardDealsPotentialCommissionCurrentMonthStatistics: true,
        DashboardRecentDeals: true,
        NewApplicationApplyOnBehalfOfClient: true,
        NewApplicationEmailApplicationLink: true,
        ReportCompletedStatistics: true,
        ReportInProgressStatistics: true,
        ApplicationDetailProgress: true,
        ApplicationDetailsExtraFields: false,
        ApplicationDetailsVehicleDetails: true,
        SettingsChangePassword: true,
        UseCustomerGuidFallback: false,
        UploadBrokerDocumentsToDSS: true,
        NewJourneyPersonalLoan: true,
        NewJourneyCarLoan: true,
        NewJourneyCommercialCarLoan: true,
        NewJourneyGreenLoan: true,
        NewJourneySARenewableEnergy: true,
        NewJourneyNSWRenewableEnergy: false,
        NewJourneyRenewableBrokerLoan: false,
        NewJourneyRenewableInterestFree: true,
        InstallerCanRenewQuoteRecord: false,
        UseNewCRMStatus: false,
        EnableTier4: true,
        ResetBalloonPayment: true,
        PlCarNewStatusDisplay: true,
        ShowRejectionReason: true,
        EnablePrivateSaleForCommercial: true,
        EnableStcUpload: true,
        EnableSettlementV2: true,
        EnableEditBorrowerInfo: true,
        EnableBalloonPaymentForAutoClassic: true,
        EnableCaravanForAutoClassic: true,
        EnableIdentityAsLoanCondition: true,
        EnableEV: true,
        EnableUpdateLoanAmountAndTermForRenewable: true,
        LoanAmountChangeEnabledBrokers: <string[]>[],
        EnableUsedCaravanAndMotorbike: true,
        DisableVerimotoForCaravanAndMotorbike: false,
        enablePrivateSaleInvoiceUIChanges: true,
        DisableVerimotoForAutoBrokerConsumerCommercial: true,
        EnableNewCommercialJourneyUI: true,
        EnableBiometricVerification: true,
        EnableConfirmUploadedDocument: true,
        EnableDisplayedAPROnContract: true,
        Enable84MonthTermForGreen: true,
        FillGapsInFlattenedData: true,
        UseBrokerPortalDealStatusDashboard: true
    },
    locale: 'en-AU',
    QuoteDocumentTimezone: 'Australia/Sydney',
    SARenewableProduct: {
        Id: 37,
        TermInMonths: 84,
        Experience: 'SARenewableEnergy'
    },
    AutomotiveQuoteUrl: 'http://localhost:3005/container/calculatorui/broker-car-loan-quote',
    AutomotiveApplicationQuoteUrl: 'http://localhost:3003/container/automotivequoteui/applicationQuote',
    QuoteCalculatorUrl: 'http://localhost:3003/container/quotecalculatorui',
    DebtCalculatorUrl: 'http://localhost:3005/container/calculatorui/debt-consolidation-quote',
    LmiCalculatorUrl: 'http://localhost:3005/container/calculatorui/lmi-vs-personal-loan',
    RedrawCalculatorUrl: 'http://localhost:3005/container/calculatorui/mortgage-redraw-calculator',
    PLServicingCalculatorUrl: 'https://prototypes.plenti.com.au/broker-servicing-calculator-pl',
    AutoServicingCalculatorUrl: 'https://prototypes.plenti.com.au/broker-servicing-calculator-auto',
    renewableVppSubsidyBrokers: <[RenewableSubsidyApplicationProvider, string[]][]>[
        [RenewableSubsidyApplicationProvider.AGL, ['AGL', 'AGL_STAFF']],
        [RenewableSubsidyApplicationProvider.SONNEN, ['SONNEN', 'SONNEN_STAFF']]
    ],
    viewOnlyRenewableVppSubsidyBrokers: <[RenewableSubsidyApplicationProvider, string[]][]>[
        [RenewableSubsidyApplicationProvider.AGL, ['AGL', 'AGL_STAFF']],
        [RenewableSubsidyApplicationProvider.SONNEN, ['SONNEN', 'SONNEN_STAFF']]
    ]
};

export type Environment = typeof environment;

export default environment;
