// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { merge } from 'lodash-es';
import { apiUrl, harmonyId, harmonyApiKey, membersUrl, marketingUrl, rootDomain } from './settings';
import { DeepPartial } from '@brokerportal/common/utils';
import environmentBase, { Environment } from './environment-base';

export const environment: Environment = merge({}, environmentBase, <DeepPartial<Environment>>{
    type: 'test',
    envName: 'test',
    fullEnvName: 'test',
    bypassAuthentication: false,
    maintenanceMode: false,
    maintenanceEstimate: '30 minutes',
    useBeamer: true,
    useDatadogRum: true,
    datadogApplicationId: '12f1e1af-d210-4a04-b788-32f83e308838',
    datadogClientToken: 'pubeb0f4fedac01c55ae15ac90a5e11e850',
    baseAppUrl: 'https://portal.test.plenti.cloud',
    membersUrl: 'https://members.test.plenti.cloud',
    maxUploadFileSize: 31457280, //30MB
    api: {
        baseUrl: apiUrl,
        identityServiceUrl: 'https://identity.test.plenti.cloud'
    },
    authentication: {
        host: apiUrl,
        token: 'portal/authentication/oauth2/token',
        key: '2e89fe6861f44cb6b283df7de90f763b',
        withCredentials: true,
        tokens: {
            access: 'access_token',
            refresh: 'refresh_token'
        }
    },
    fusionAuth: {
        clientId: 'a39e6925-bb05-419b-a96b-b3fb78a9a19d',
        baseUrl: 'https://plenti-test.fusionauth.io',
        tenantId: '49e65e34-86e9-02f9-2573-7cc82bef1c20',
        redirectUri: 'https://members.test.plenti.cloud/login/Auth'
    },
    harmony: {
        userName: harmonyId,
        key: harmonyApiKey
    },
    useDirectQuoteService: false,
    quoteServiceHost: '',
    providerBatteryPackagesRoute: 'productpackages',
    schemeCoverageRoute: 'schemeCoverage',
    approvedBuildersRoute: 'quotebuilders',
    constants: {
        branding: {
            title: 'Plenti',
            membersUrl: membersUrl,
            marketingUrl: marketingUrl,
            staticAssetsPath: '//static.plenti.com.au',
            images: {
                logoUrl: '//test.cdn.ratesetter.com.au/images/logo.svg'
            },
            contact: {
                contactEmail: `contact@${rootDomain}`,
                contactCarEmail: `carloans@${rootDomain}`,
                contactEHPEmail: `empoweringhomes@${rootDomain}`,
                contactHBSEmail: `homebatteryscheme@${rootDomain}`,
                contactPurpleBricksEmail: `purplebricks@${rootDomain}`,
                contactEnergyMasterEmail: `energymasters@${rootDomain}`
            },
            urls: {
                creditAdvicePL: 'https://web.ratesetter.com.au/broker/credit-advice/personal-loans',
                creditAdviceCar: 'https://web.ratesetter.com.au/broker/credit-advice/car-loans'
            }
        },
        storage: {
            portalConfigurationKey: 'RSBrokerPortalConfiguration_TEST',
            brokerSettingConfigurationKey: 'RSBrokerSettingConfiguration_TEST',
            workflowManifestConfigurationKey: 'RSWorkflowManifestConfiguration_TEST'
        },
        cookies: {
            oldLoginCookieName: 'rs_broker_access_token_truncated',
            brokerAccessTokenCookieName: 'rs_broker_access_token',
            brokerAccessTokenIndicatorCookieName: 'rs_broker_access_token_indicator',
            brokerAccessTokenTruncatedCookieName: 'rs_broker_access_token_truncated',
            brokerRefreshTokenCookieName: 'rs_broker_refresh_token'
        },
        settings: {
            tokenRefreshIntervalSeconds: 300
        },
        urls: {
            continueApplication: `${membersUrl}/applications/referral/continue/{applicationId}/{isBroker}`,
            continueApplicationStep: `${membersUrl}/applications/referral/continue/{applicationId}/{isBroker}/{stepName}`,
            referApplication: `${membersUrl}/refer/{referralCode}/{isBroker}?data={data}`,
            referExistingApplication: `${membersUrl}/applications/referral/refer/{applicationId}/{isBroker}`
        },
        assets: {
            defaultAvatarImageUrl: '/assets/img/avatars/noavatar.png'
        },
        redbookDeploymentStage: 'test'
    },
    microUi: {
        baseUrl: 'https://test.cdn.ratesetter.com.au/ui/BrokerPortalReactUi',
        useAppJsonIndirection: true
    },
    featureScope: 'Test',
    features: {
        DashboardDealsCompletedCurrentMonthStatistics: true,
        DashboardDealsCommissionEarnedCurrentMonthStatistics: true,
        DashboardDealsPotentialCommissionCurrentMonthStatistics: true,
        DashboardRecentDeals: true,
        DashboardNews: true,
        NewApplicationApplyOnBehalfOfClient: true,
        NewApplicationEmailApplicationLink: true,
        ReportCompletedStatistics: true,
        ReportInProgressStatistics: true,
        ApplicationDetailProgress: true,
        ApplicationDetailsExtraFields: false,
        ApplicationDetailsVehicleDetails: true,
        SettingsChangePassword: true,
        UseCustomerGuidFallback: false,
        UploadBrokerDocumentsToDSS: true,
        NewJourneyPersonalLoan: true,
        NewJourneyCarLoan: true,
        NewJourneyCommercialCarLoan: true,
        NewJourneyGreenLoan: true,
        NewJourneySARenewableEnergy: true,
        NewJourneyNSWRenewableEnergy: false,
        NewJourneyRenewableBrokerLoan: false,
        NewJourneyRenewableInterestFree: true,
        InstallerCanRenewQuoteRecord: false,
        UseNewCRMStatus: false,
        EnableTier4: true,
        ResetBalloonPayment: true,
        PlCarNewStatusDisplay: true,
        ShowRejectionReason: true,
        EnablePrivateSaleForCommercial: true,
        EnableStcUpload: true,
        EnableSettlementV2: true,
        EnableEditBorrowerInfo: true,
        EnableBalloonPaymentForAutoClassic: true,
        EnableCaravanForAutoClassic: true,
        EnableIdentityAsLoanCondition: true,
        EnableEV: true,
        EnableUpdateLoanAmountAndTermForRenewable: true,
        LoanAmountChangeEnabledBrokers: [],
        EnableUsedCaravanAndMotorbike: true,
        DisableVerimotoForCaravanAndMotorbike: false,
        enablePrivateSaleInvoiceUIChanges: true,
        DisableVerimotoForAutoBrokerConsumerCommercial: true,
        EnableNewCommercialJourneyUI: true,
        EnableBiometricVerification: true,
        EnableConfirmUploadedDocument: true,
        EnableDisplayedAPROnContract: true,
        FillGapsInFlattenedData: true,
        UseBrokerPortalDealStatusDashboard: true
    },
    locale: 'en-AU',
    QuoteDocumentTimezone: 'Australia/Sydney',
    SARenewableProduct: {
        Id: 37,
        TermInMonths: 84,
        Experience: 'SARenewableEnergy'
    },
    AutomotiveQuoteUrl: `${membersUrl}/container/calculatorui/broker-car-loan-quote`,
    AutomotiveApplicationQuoteUrl: `${membersUrl}/container/automotivequoteui/applicationQuote`,
    QuoteCalculatorUrl: `${membersUrl}/container/quotecalculatorui`,
    DebtCalculatorUrl: `${membersUrl}/container/calculatorui/debt-consolidation-quote`,
    LmiCalculatorUrl: `${membersUrl}/container/calculatorui/lmi-vs-personal-loan`,
    RedrawCalculatorUrl: `${membersUrl}/container/calculatorui/mortgage-redraw-calculator`
});

export default environment;
